import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Layout from '../components/common/Layout'
import { Seo } from '../components/meta/Seo'
import SliceZone from '../components/slices/SliceZone'

const About = () => {
  const aboutData = useStaticQuery(graphql`
    query AboutPage {
      prismicPage(data: { page_title: { text: { eq: "About" } } }) {
        id
        data {
          body {
            ... on PrismicPageDataBodyAboutSection {
              primary {
                title {
                  text
                  html
                }
                main_image {
                  gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
                }
                description {
                  text
                }
              }
              slice_label
              slice_type
            }
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <Seo
        title="About"
        description="Learn more about us who we are and what we do."
      />
      <main>
        <SliceZone slices={aboutData.prismicPage.data.body} />
      </main>
    </Layout>
  )
}

export default About
